.App {
  text-align: center;
  /* background-color: rgb(35, 39, 35); */
  height:100vh;
}
.App :only-child{
  height:100%;
}
.App > div > .container-fluid{
  height:100%;
}
.front_logo {
  width: 120px;
  height: auto;
  pointer-events: none;
}
.bg-success-50 {
  --bs-bg-opacity: .5;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
@media (prefers-reduced-motion: no-preference) {
  .front-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4d628b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#sidebarMenu{
  padding-left:0;
  padding-right:0;
  overflow-x: hidden;
  min-height: 100vh;
}
.bg-theme{
  background-color: rgb(35, 39, 35);
}
.nav-item{
  font-size: large;
}
a.nav-link{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
  color:white;
}
a.nav-link :nth-child(1){
  padding-right:1em;
}
.nav-item .active{
  background-color: green;
  /* #d44d81;  */
  /* #0d6efd; */
  color:white;
}
.text-large{
  font-size:3rem;
}
.text-medium{
  font-size:2rem;
}
.head-row{
  font-weight: bold;
  text-align: left;
  padding:0.5em;
}
.list-item,.list-item-shade{
padding:0.5em 0.25em;
cursor: pointer;
text-align: left;
}
.list-item:hover{
  background-color: rgba(121, 170, 216, 0.849) !important;
}
.list-item-shade{
  background-color: rgba(154, 165, 175, 0.623);
  }
  /* .input[type="submit"],.form-control input[type="button"]{
    max-width:125px !important;
  } */

.spinner,.spinner-medium,.spinner-large{
  border-radius: 50%;
  border-bottom: 0;
  border-right: 0;
  display: block;
  animation: spin infinite 1s linear;
}
.spinner{
  margin:0.25em auto;
  width:1em !important;
  height:1em !important;
  /* border: 2px solid white; */
  border-left:2px solid white;
  border-top:2px solid white;
}
.spinner-medium{
  margin:0.5em auto;
  width:3em !important;
  height:3em !important;
  /* border: 2px solid white; */
  border-left:2px solid green;
  border-top:2px solid green;
}
.spinner-large{
  width:130px;
  height:130px !important;
  margin:2em auto;
  /* border: 2px solid royalblue; */
  border-left:2px solid royalblue;
}
.hidden{
  display: none !important;
}
.summary-card{
  padding-top:1em;
  cursor: pointer;
  background-color: white;
}
.summary-card:hover{
  background-color: rgb(56, 56, 138);
  color:white !important;
}
.summary-card:hover p{
  color:white !important;
}
.summary-card > span.head{
  border-bottom: 1px solid grey;
  width: 100%;
}
.progress{
  min-width:0;
}
.text-left{
  text-align: left !important;
}
.text-right{
  text-align: right !important;
}
.text-sm-center{
  text-align: center !important;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255,102,203);
} 

.timebox{
  width:fit-content !important;
  text-align: center;
}

.timebox-medium{
  width:fit-content !important;
  text-align: left;
}
@media screen and (max-width:767px) {
  .w-sm-100{
width:100% !important;
}
}

canvas{
  width:70% !important;
  height: 70% !important;
  margin:0 auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@media print{
  .section-to-print {
    position: absolute;
    left: 1em;
    top: 0;
    margin:1em auto;
    font-size: 1em;
    width:calc(100% - 5em);
  }
  .section-to-print .print-signature {
    font-size: small;
    color:#4d628b;
  }
}